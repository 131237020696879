<template>
  <b-card>
    <div class="d-flex justify-content-between align-items-center">
      <h6>
        {{ `${$t('fields.result')} (${total})` }}
        <span
          v-if="isFetching"
          class="text-muted"
        >กำลังโหลด...</span>
      </h6>
      <b-button
        v-if="type==='deposit'"
        variant="relief-success"
        @click="onToggleTopupManual({})"
      >
        เติมมือ
      </b-button>
    </div>
    <b-row class="my-2">
      <b-col
        v-if="isOwner"
        cols="3"
      >
        <master-select-input 
          :value="selectedMaster"
          hide-label
          @update="onMasterIdChange"
        />
      </b-col>
      <b-col
        v-if="isOwner || isMaster"
        cols="3"
      >
        <agent-select-input
          :value="selectedAgent"
          :master-id="selectedMaster"
          hide-label
          @update="onAgentIdChange"
        />
      </b-col>
    </b-row>
    <!-- Table -->
    <b-table
      :items="hiddenList"
      :fields="type==='deposit'? depositFields: withdrawFields"
      responsive
      show-empty
    >
      <template #cell(#)="data">
        {{ data.index + 1 }}
      </template>
      
      <template #cell(detail)="data">
        <span
          v-if="data.value"
          class="text-danger"
        > {{ data.value }}</span>
        <span v-else>-</span>
      </template>
      <template #cell(transactionDateTime)="data">
        {{ data.value | datetime }}
      </template>
      <template #cell(createdAt)="data">
        {{ data.value | datetime }}
      </template>
      <template #cell(fromBankCode)="data">
        <b-media vertical-align="center">
          <template #aside>
            <BankIcon :bank-code="data.value" />
          </template>
          {{ data.item.fromAccountNumber }}<br>
          {{ data.item.fromAccountName }}
        </b-media>
      </template>
      <template #cell(user)="data">
        <b-media vertical-align="center">
          <template #aside>
            <BankIcon :bank-code="data.value.bankCode" />
          </template>
          {{ data.value.bankAccountNumber }}<br>
          {{ data.value.firstname }} {{ data.value.lastname }}
        </b-media>
      </template>
      <template #cell(agentBankAccount)="data">
        <b-media vertical-align="center">
          <template #aside>
            <BankIcon :bank-code="data.value.bankCode" />
          </template>
          {{ data.value.bankAccountNumber }}<br>
          {{ data.value.bankAccountName }}
        </b-media>
      </template>
      <template #cell(transactionChannelCode)="data">
        <b-badge>
          {{ data.item.transactionChannelDescription || '-' }}
          ({{ data.value || '-' }})
        </b-badge>
      </template>
      <template #cell(afterBalance)="data">
        <b-badge class="badge-soft-danger">
          {{ data.item.beforeBalance | currency }}
        </b-badge><br>
        <b-badge class="badge-soft-success">
          {{ data.value | currency }}
        </b-badge>
      </template>
      
      <template #cell(operation)="data">
        <b-button-group size="sm">
          <b-button
            variant="info"
            @click="onApprovePendingCredit(data.item, 'adjust')"
          >
            ปรับยอด
          </b-button>
          <b-button
            variant="danger"
          >
            {{ $t('buttons.remove') }}
          </b-button>
        </b-button-group>
      </template>
      <template #cell(operation2)="data">
        <b-button-group size="sm">
          <b-button-group size="sm">
            <b-button
              variant="info"
              @click="onApproveWithdraw(data.item, 'auto')"
            >
              ถอนออโต้
            </b-button>
            <b-button
              variant="warning"
              @click="onApproveWithdraw(data.item, 'manual')"
            >
              ถอนมือ
            </b-button>
            <b-button
              variant="danger"
              @click="onRejectPendingCredit(data.item.id)"
            >
              ปฎิเสธ
            </b-button>
          </b-button-group><br>
        </b-button-group><br>
        <b-button-group size="sm">
          <b-button
            variant="light"
            @click="$router.push(`/credits/all?search=${data.item.user.username}`)"
          >
            รายงานเครดิต
          </b-button>
        </b-button-group>
      </template>
      <template #empty="">
        <p class="text-center text-muted">
          {{ $t('messages.nothing_here') }}
        </p>
      </template>
    </b-table>
    <!-- pagination -->
    <b-row>
      <b-col md="4">
        <page-limit-select
          :value="limit"
          @update="onLimitChange"
        />
      </b-col>
      <b-col>
        <pagination-input
          :per-page="limit"
          :total="total"
          @update="(val) => currentPage = val"
        />
      </b-col>
    </b-row>

    <approve-withdraw-modal
      :txn="selectedTxn"
      :approve-type="approveType"
    />
    <!--    <topup-manual-modal :txn="selectedTxn"></topup-manual-modal>-->
    <reject-withdraw-modal :txn-id="selectedTxnId" />
  </b-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  name: 'PendingTransactions',
  components: {
    ApproveWithdrawModal: () => import('./ApproveWithdrawModal.vue'),
    RejectWithdrawModal: () => import('./RejectWithdrawModal.vue'),
  },
  props: {
    type: {
      type: String,
      default: 'deposit',
    },
  },
  data() {
    return {
      currentPage: 1,
      selectedLimit: 20,
      selectedTxn: {},
      depositFields: [
        '#',
        {
          key: 'channel',
          label: 'ช่องทาง',
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'bank_code',
          label: 'บัญชีโอน',
          thStyle: {
            minWidth: '200px',
          },
        },
        {
          key: 'receive',
          label: 'บัญชีรับ',
          thStyle: {
            minWidth: '200px',
          },
        },
        {
          key: 'amount',
          label: this.$t('credits.credit_amount'),
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'remain',
          label: 'คงเหลือ (ก่อน-หลัง)',
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'created_at',
          label: 'เวลาโอน',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'operation',
          label: `${this.$t('buttons.operation')}`,
          class: 'text-right',
          thStyle: {
            minWidth: '220px',
          },
        },
      ],
      withdrawFields: [
        '#',
        {
          key: 'user',
          label: 'บัญชี',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'amount',
          label: this.$t('credits.credit_amount'),
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'createdAt',
          label: 'เวลาแจ้ง',
          thStyle: {
            minWidth: '130px',
          },
        },
         {
          key: 'remark',
          label: 'หมายเหตุ',
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'operation2',
          label: `${this.$t('buttons.operation')}`,
          class: 'text-right',
          thStyle: {
            minWidth: '200px',
          },
        },
      ],
      rejectForm: { txnId: '', detail: '', isReturnCredit: false },
      approveType: 'adjust',
      selectedTxnId: '',
      selectedMaster: '',
      selectedAgent: '',
    }
  },
  computed: {
    ...mapState({
      isFetchingWithdrawHide: (state) => state.withdraw.isFetchingWithdrawHide,
      isRejecting: (state) => state.credit.isRejectingPlayerWithdraw,
      isRejectingSuccess: (state) =>
        state.credit.isRejectingPlayerWithdrawSuccess,
    }),
    ...mapGetters(['isOwner', 'isMaster', 'withdrawHide']),
    hiddenList() {
      return this.withdrawHide.transactions.items 
    },
    pagination() {
      return this.withdrawHide.transactions.meta || {itemsPerPage: 0,totalItems: 0 }
    },
    limit() {
      return this.pagination.itemsPerPage
    },
    total() {
      return this.pagination.totalItems
    },
    isFetching() {
      return this.isFetchingWithdrawHide
    },
  },
  watch: {
    currentPage(val) {
       if(val){
        this.$addPageToLocation(val)
        this.fetchData()
      }
    },
    isRejectingSuccess(val) {
      if (val) {
        this.onCloseRejectModal()
      }
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions([
      'fetchWithdrawHide',
      'rejectPlayerWithdraw',
      'acceptPlayerWithdraw',
      'manualSetStatusToSuccess',
    ]),
    fetchData() {
      this.fetchWithdrawHide({
        limit: this.selectedLimit,
        page: this.currentPage,
        search: '',
        masterId: this.selectedMaster,
        agentId: this.selectedAgent,
      })
    },
    onLimitChange(limit) {
      if(limit){
        this.selectedLimit = limit
        this.fetchData()
      }
    },
    onAgentIdChange(agentId) {
      this.selectedAgent = agentId
      this.fetchData()
    },
    onMasterIdChange(masterId) {
      this.selectedMaster = masterId
      this.fetchData()
    },
    onApproveWithdraw(txn, type) {
      this.$bvModal.show('approve-withdraw-modal')
      this.selectedTxn = txn
      this.approveType = type
    },
    onRejectPendingCredit(id) {
      this.$bvModal.show('reject-withdraw-modal')
      this.selectedTxnId = id
    },
    onApprovePendingCredit(txn, type) {
      this.$bvModal.show('approve-withdraw-modal')
      this.selectedTxn = txn
      this.approveType = type
    },
    onToggleTopupManual(txn) {
      this.selectedTxn = txn
      // this.$bvModal.show('topup-manual-modal')
    },
  },
}
</script>
